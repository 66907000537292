import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PaymentComponent = () => {
  const [paymentInfo, setPaymentInfo] = useState({
    amount: 0,
    payment_type: '',
  });
  const [loading, setLoading] = useState(true);
  const url = process.env.REACT_APP_API_URL_LOCAL;

  useEffect(() => {
    // Appel API pour récupérer les informations de paiement
    axios
      .get(`${url}/payment-info`)
      .then((response) => {
        setPaymentInfo(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(
          'Erreur lors de la récupération des informations de paiement:',
          error,
        );
        setLoading(false);
      });
  }, [url]);

  if (loading) {
    return <div>Chargement...</div>;
  }

  return (
    <div>
      <h2>Choix du Paiement</h2>
      <p>Montant à payer : {paymentInfo.amount} FCFA</p>
      <p>Méthode de paiement : {paymentInfo.payment_type}</p>

      {/* Vous pouvez maintenant afficher le formulaire pour procéder au paiement */}
      <button onClick={() => handlePayment(paymentInfo)}>
        Payer maintenant avec {paymentInfo.payment_type}
      </button>
    </div>
  );
};

const handlePayment = async (paymentInfo) => {
  const url = process.env.REACT_APP_API_URL_LOCAL;

  try {
    if (paymentInfo.payment_type === 'Orange Money') {
      // Appel à l'API Laravel pour démarrer le paiement via Orange Money
      const response = await axios.post(`${url}/start-orange-money-payment`, {
        amount: paymentInfo.amount,
        payment_type: paymentInfo.payment_type,
      });

      // Vérifiez la réponse pour rediriger l'utilisateur ou afficher un message
      if (response.data.success) {
        // Redirection vers la page de paiement ou affichez le QR Code/lien de paiement
        window.location.href = response.data.payment_url;
      } else {
        alert('Échec du démarrage du paiement. Veuillez réessayer.');
      }
    } else {
      alert('Méthode de paiement non prise en charge.');
    }
  } catch (error) {
    console.error('Erreur lors du démarrage du paiement:', error);
    alert('Une erreur est survenue. Veuillez réessayer.');
  }
};

export default PaymentComponent;
