import Swal from 'sweetalert2';

function log(params) {
  if (typeof params === 'object') {
    console.log(
      '%c' + JSON.stringify(params, null, 2),
      'color: #3498db; font-size: 16px; font-weight: bold; background: #ecf0f1; padding: 2px 4px; border-radius: 4px;',
    );
  } else {
    console.log(
      '%c' + params,
      'color: #3498db; font-size: 16px; font-weight: bold; background: #ecf0f1; padding: 2px 4px; border-radius: 4px;',
    );
  }
}

function showAlert(title, message) {
  Swal.fire({
    title: title,
    text: message,
    icon: 'success',
  });
}

function ForEach(array, cle) {
  array.forEach((element) => {
    console.log(element[cle]);
  });
}

export { log, showAlert, ForEach };
