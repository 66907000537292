import React, { useEffect, useState, useContext } from "react";
import { groupBy } from "lodash";
import { CartContext } from "../services/CartContext";
import Header from "../common/Header";
import apiService from "../services/api";
import { CLASSES_PAR_CATEGORIES, DAKAR, ERROR_RECUP } from "../constant/_const";
import Loader from "../common/Loader";
import Footer from "../common/Footer";
import PaymentUtils from "../modules/Payment/PaymentUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faLayerGroup,
  faSchool,
} from "@fortawesome/free-solid-svg-icons";
import CouponUtils from "../modules/Coupon/CouponUtils";

const Panier = () => {
  const { cartItems, removeFromCart } = useContext(CartContext);
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [numberOfStudents, setNumberOfStudents] = useState(1);
  const [quantities, setQuantities] = useState({});
  const [dakarDistrict, setDakarDistrict] = useState([]);
  const [school, setschool] = useState([]);
  const [className, setclassName] = useState([]);
  const [classCategory, setclassCategory] = useState([]);
  useEffect(() => {
    const storedId = localStorage.getItem("id");
    if (storedId) {
      setId(storedId);
    } else {
      console.error("ID non trouvé dans localStorage");
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchCategoryDetails = async () => {
      if (id) {
        try {
          const response = await apiService.get(
            `${CLASSES_PAR_CATEGORIES}/${id}`
          );
          setCategoryDetails(response.data);
        } catch (error) {
          console.error(ERROR_RECUP, error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchCategoryDetails();
  }, [id]);

  useEffect(() => {
    const fetchDakarDistrict = async () => {
      try {
        setLoading(true);
        const response = await apiService.get(DAKAR);
        setDakarDistrict(response);
      } catch (error) {
        console.error(ERROR_RECUP, error);
      } finally {
        setLoading(false);
      }
    };
    fetchDakarDistrict();
  }, []);

  /*
  Calculer le total en fonction du nombre d'élèves
  */
  const calculateTotal = () => {
    const totalPerStudent = cartItems.reduce((total, item) => {
      const quantity = quantities[item.id] || 1;
      return total + (parseFloat(item.price) * quantity || 0);
    }, 0);
    return totalPerStudent * numberOfStudents;
  };

  /*
  Gestion du nombre d'élèves
  */
  const incrementStudents = () => setNumberOfStudents((prev) => prev + 1);
  const decrementStudents = () =>
    setNumberOfStudents((prev) => Math.max(1, prev - 1));

  const groupedItems = groupBy(cartItems, (item) => {
    const supplyType =
      typeof item.supply_type === "object"
        ? item.supply_type.name || "Autre"
        : item.supply_type;
    return item.provided_by_school ? supplyType : "Accessoires";
  });

  const incrementQuantity = (id) =>
    setQuantities((prev) => ({ ...prev, [id]: (prev[id] || 1) + 1 }));

  const decrementQuantity = (id) =>
    setQuantities((prev) => ({
      ...prev,
      [id]: Math.max(1, (prev[id] || 1) - 1),
    }));

  useEffect(() => {
    const storedData = localStorage.getItem("detailsOffers");
    if (storedData) {
      const { school, className, classCategory } = JSON.parse(storedData);
      setschool(school);
      setclassName(className);
      setclassCategory(classCategory);
    }
  }, []);

  const order = {
    schoolName:school,
    className: className,
    classCategory: classCategory,
    number_of_students: numberOfStudents,
  };

  if (loading) return <Loader />;

  return (
    <>
      <Header />
      <br />
      <section className="bg-white antialiased dark:bg-gray-900 pb-20">
        <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
          <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-md border border-gray-300 dark:border-gray-700 shadow-lg">
            {cartItems.length > 0 && categoryDetails ? (
              <>
                <div className="flex items-center mb-2">
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    className="text-blue-500 mr-2"
                  />
                  <p className="text-xl font-semibold text-gray-700 dark:text-white">
                    Classe : {className}
                  </p>
                </div>
                <div className="flex items-center mb-2">
                  <FontAwesomeIcon
                    icon={faLayerGroup}
                    className="text-green-500 mr-2"
                  />
                  <p className="text-xl font-semibold text-gray-700 dark:text-gray-300">
                    Niveau : {classCategory}
                  </p>
                </div>
                <div className="flex items-center mb-4">
                  <FontAwesomeIcon
                    icon={faSchool}
                    className="text-yellow-500 mr-2"
                  />
                  <p className="text-lg font-semibold text-gray-700 dark:text-gray-400">
                    Ecole : {school}
                  </p>
                </div>
              </>
            ) : cartItems.length === 0 ? (
              <></>
            ) : (
              <p className="text-gray-900 dark:text-white">
                Chargement des détails de la catégorie...
              </p>
            )}
          </div>
          <div className="mt-6 sm:mt-8 md:gap-6 lg:flex lg:items-start xl:gap-8">
            <div className="w-full">
              {Object.entries(groupedItems).length > 0 ? (
                Object.entries(groupedItems).map(([type, items]) => {
                  let displayType =
                    typeof type === "object"
                      ? type.name || "Type inconnu"
                      : type;

                  return (
                    <div
                      key={displayType}
                      className="mb-8 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg"
                    >
                      <h4 className="text-xl font-bold text-gray-900 dark:text-white border-b-2 border-gray-300 dark:border-gray-700 pb-2 mb-4">
                        {displayType}
                      </h4>
                      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        {items.map((item) => (
                          <div
                            key={item.id}
                            className="bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-200 ease-in-out p-6 flex flex-col justify-between"
                          >
                            <h5 className="font-semibold text-lg text-gray-900 dark:text-white mb-2">
                              {item.name}
                            </h5>
                            <p className="text-md text-gray-700 dark:text-gray-300 mb-4">
                              Prix:{" "}
                              <span className="font-semibold">
                                {parseFloat(item.price).toFixed(
                                  parseFloat(item.price) % 1 === 0 ? 0 : 2
                                )}{" "}
                                Fcfa
                              </span>
                            </p>

                            <div className="flex justify-between">
                              <div className="flex items-center gap-4">
                                <button
                                  onClick={() => decrementQuantity(item.id)}
                                  className="group rounded-[50px] border border-gray-200 shadow-sm shadow-transparent p-2.5 flex items-center justify-center bg-white transition-all duration-500 hover:shadow-gray-200 hover:bg-gray-50 hover:border-gray-300 focus-within:outline-gray-300"
                                >
                                  <svg
                                    className="stroke-gray-900 transition-all duration-500 group-hover:stroke-black"
                                    width={18}
                                    height={19}
                                    viewBox="0 0 18 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.5 9.5H13.5"
                                      stroke="currentColor"
                                      strokeWidth="1.6"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                                <input
                                  type="text"
                                  id={`counter-input-${item.id}`}
                                  className="border border-gray-200 rounded-full w-10 aspect-square outline-none text-gray-900 font-semibold text-sm py-1.5 px-3 bg-gray-100 text-center"
                                  value={quantities[item.id] || 1}
                                  readOnly
                                />

                                <button
                                  onClick={() => incrementQuantity(item.id)}
                                  className="group rounded-[50px] border border-gray-200 shadow-sm shadow-transparent p-2.5 flex items-center justify-center bg-white transition-all duration-500 hover:shadow-gray-200 hover:bg-gray-50 hover:border-gray-300 focus-within:outline-gray-300"
                                >
                                  <svg
                                    className="stroke-gray-900 transition-all duration-500 group-hover:stroke-black"
                                    width={18}
                                    height={19}
                                    viewBox="0 0 18 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M3.75 9.5H14.25M9 14.75V4.25"
                                      stroke="currentColor"
                                      strokeWidth="1.6"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              </div>
                              <div>
                                <button
                                  onClick={() => removeFromCart(item.id)}
                                  className="rounded-full group flex items-center justify-center focus-within:outline-red-500"
                                >
                                  <svg
                                    width={34}
                                    height={34}
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      className="fill-red-50 transition-all duration-500 group-hover:fill-red-400"
                                      cx={17}
                                      cy={17}
                                      r={17}
                                      fill="currentColor"
                                    />
                                    <path
                                      className="stroke-red-500 transition-all duration-500 group-hover:stroke-white"
                                      d="M14.1673 13.5997V12.5923C14.1673 11.8968 14.7311 11.333 15.4266 11.333H18.5747C19.2702 11.333 19.834 11.8968 19.834 12.5923V13.5997M19.834 13.5997C19.834 13.5997 14.6534 13.5997 11.334 13.5997C6.90804 13.5998 27.0933 13.5998 22.6673 13.5997C21.5608 13.5997 19.834 13.5997 19.834 13.5997ZM12.4673 13.5997H21.534V18.8886C21.534 20.6695 21.534 21.5599 20.9807 22.1131C20.4275 22.6664 19.5371 22.6664 17.7562 22.6664H16.2451C14.4642 22.6664 13.5738 22.6664 13.0206 22.1131C12.4673 21.5599 12.4673 20.6695 12.4673 18.8886V13.5997Z"
                                      stroke="#EF4444"
                                      strokeWidth="1.6"
                                      strokeLinecap="round"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-gray-900 dark:text-white">
                  Votre panier est vide.
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="fixed bottom-0 right-0 pb-5 pr-4 bg-white shadow-lg rounded-lg sm:w-full lg:w-full lg:max-w-md border border-gray-300 dark:bg-gray-800 dark:border-gray-600">
        <div className="flex flex-col px-4 py-3 space-y-4">
          {/* <!-- Nombre d'élèves --> */}
          <div className="flex items-center justify-between">
            <span className="text-xl font-semibold text-gray-900 dark:text-gray-100">
              Nombre d'élèves &nbsp;
            </span>
            <div className="flex items-center space-x-2">
              <button
                type="button"
                className="flex-shrink-0 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600 rounded-full h-10 w-10 flex items-center justify-center focus:outline-none"
                onClick={decrementStudents}
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 2"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1 1h16"
                  />
                </svg>
              </button>
              <input
                type="text"
                className="text-gray-900 dark:text-gray-100 border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 rounded-md text-center text-lg font-semibold w-20 focus:outline-none"
                readOnly
                value={numberOfStudents}
              />
              <button
                type="button"
                className="flex-shrink-0 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600 rounded-full h-10 w-10 flex items-center justify-center focus:outline-none"
                onClick={incrementStudents}
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* <!-- Total --> */}
          <div>
            <span className="text-lg font-semibold text-gray-900 dark:text-gray-100">
              Total :&nbsp;
              <span className="text-red-600 font-bold">
                {parseFloat(calculateTotal()).toFixed(
                  parseFloat(calculateTotal()) % 1 === 0 ? 0 : 2
                )}{" "}
                FCFA
              </span>
            </span>
          </div>
          {/* <!-- Payment Button --> */}
          <div className="flex justify-between">
            <PaymentUtils dakarDistrict={dakarDistrict} order={order} />
            <CouponUtils dakarDistrict={dakarDistrict} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Panier;
