export const CLASS_CATEGORIES = 'class_categories';
export const CLASSES = 'classes';
export const SCHOOLS = 'schools';
export const CLASSES_SUPPLIES = 'class_supplies';
export const SUPPLIES = 'supplies';
export const CLASSES_PAR_CATEGORIES = 'classes_par_categories';
export const DAKAR = 'dakarAdmin';
export const CREER_PAIEMENT = '/payment/create';
// export const SUPPLIES_PAR_CLASSES = `/classes/${id}/supplies/`;
export const ERROR_RECUP = 'Erreur lors de la récupération';
export const OLD_PATH = 'public/';
export const NEW_PATH = 'storage/';
export const HTTP_PATH_MEDIA = OLD_PATH + NEW_PATH;

