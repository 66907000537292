  import React from 'react';
  import ReactDOM from 'react-dom/client';
  import 'flowbite/dist/flowbite.css';
  import 'flowbite';
  import './index.css';
  import 'react-loading-skeleton/dist/skeleton.css';
  import 'animate.css';
  import App from './App';
  import reportWebVitals from './reportWebVitals';
  import { CartProvider } from './services/CartContext';
  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
    <React.StrictMode>
      <CartProvider>
        <App />
      </CartProvider>
    </React.StrictMode>,
  );

  reportWebVitals();