import React, { useContext, useEffect, useState } from "react";
import Loader from "../../common/Loader";
import Swal from "sweetalert2";
import { CartContext } from "../../services/CartContext";
import apiService from "../../services/api";
import { CREER_PAIEMENT } from "../../constant/_const";
import deliveryData from "../../data/delivery-data-ouverture.json";
import { initFlowbite } from "flowbite";

export function PaymentUtils({ dakarDistrict, order }) {
  const { cartItems } = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [region, setRegion] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [districtPrice, setDistrictPrice] = useState("");

  useEffect(() => {
    initFlowbite();
  }, []);

  /* event select region-changes */

  const handleRegionChange = (e) => {
    const newRegion = e.target.value;
    setRegion(newRegion);

    if (newRegion !== "Dakar") {
      setSelectedDistrict("");
      setDistrictPrice(2000);
    } else {
      setDistrictPrice("");
    }
  };

  /* event select district-changes */
  const handleDistrictChange = (e) => {
    const selected = e.target.value;
    setSelectedDistrict(selected);
    const districtData = dakarDistrict.find(
      (district) => district.district === selected
    );
    if (districtData) {
      setDistrictPrice(districtData.price);
    } else {
      setDistrictPrice("");
    }
  };

  /* calcule total articles */
  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      return total + (parseFloat(item.price) || 0);
    }, 0);
  };

  /* calculate total articles + livraison */
  const totalApayer =
    parseFloat(calculateTotal()) + parseFloat(districtPrice || 0);

  /* desactiver button passer a la caisse */
  const isButtonDisabled = calculateTotal() <= 0;

  /* validation formulaire */
  const validateForm = () => {
    if (!fullName || !phoneNumber || !region) {
      Swal.fire("Erreur", "Tous les champs sont requis.", "error");
      return false;
    }

    /* Si la région est "Dakar", le district devient obligatoire */
    if (region === "Dakar" && !selectedDistrict) {
      Swal.fire("Erreur", "Veuillez sélectionner un district.", "error");
      return false;
    }

    const phoneRegex = /^[0-9]{9}$/;
    if (!phoneRegex.test(phoneNumber)) {
      Swal.fire(
        "Erreur",
        "Le numéro de téléphone doit comporter exactement 9 chiffres.",
        "error"
      );
      return false;
    }

    if (calculateTotal() <= 0) {
      Swal.fire(
        "Erreur",
        "Le montant total doit être supérieur à zéro.",
        "error"
      );
      return false;
    }

    return true;
  };

  /* Paiement processing */
  const ConfirmerPaiement = async () => {
    if (!validateForm()) {
      return;
    }

    Swal.fire({
      title: "Vous confirmez ?",
      text: "Êtes-vous sûr de vouloir confirmer le paiement ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, confirmer !",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const paymentData = {
          fullName,
          phoneNumber,
          region,
          total: totalApayer,
          order: order,
        };
        // console.log(paymentData);
        
        let timerInterval;
        Swal.fire({
          title: "Traitement en cours",
          html: "Redirection dans <b></b> secondes.",
          timer: 10000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              const remainingTime = Swal.getTimerLeft() / 1000;
              b.textContent = Math.ceil(remainingTime);
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });

        try {
          const response = await apiService.post(CREER_PAIEMENT, {
            fullName,
            phoneNumber,
            region,
            total: paymentData.total,
            order: paymentData.order,
          });
          if (response.payment_url) {
            Swal.close();
            window.location.href = response.payment_url;
          }
        } catch (error) {
          console.error("Erreur lors du traitement du paiement :", error);
          Swal.fire(
            "Erreur",
            "Une erreur est survenue lors du traitement du paiement.",
            "error"
          );
        }
      }
    });
  };

  if (loading) return <Loader />;

  return (
    <>
      <button
        data-modal-target="paimentprocessing"
        data-modal-toggle="paimentprocessing"
        className={`px-4 py-2 rounded-md font-semibold text-white transition-colors duration-300 ${
          isButtonDisabled
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-blue-600 hover:bg-blue-700"
        }`}
        type="button"
        id="btnToDesabled"
        disabled={isButtonDisabled}
      >
        Passer à la caisse
      </button>

      <div
        id="paimentprocessing"
        tabIndex={-1}
        className=" myPaymentUtils fixed top-0 left-0 right-0 z-50 hidden w-full p-4  overflow-x-hidden overflow-y-auto md:inset-0 h-full max-h-full"
      >
        <div className="relative w-full max-w-4xl max-h-full animate__animated animate__fadeInDown">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <form className="pb-4 space-y-2">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-green-800 dark:text-white">
                  FINALISER LE PAIEMENT
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="paimentprocessing"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5 space-y-4">
                <section className="bg-white dark:bg-gray-900 ">
                  <div className=" px-4 mx-auto max-w-screen-md space-y-5">
                    <div className="space-y-4">
                      <div>
                        <label
                          htmlFor="regions"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Choisissez votre région
                        </label>
                        <select
                          id="regions"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          value={region}
                          onChange={handleRegionChange}
                          required
                        >
                          <option value="" disabled>
                            Sélectionnez une région
                          </option>
                          {deliveryData.regions.map((region, index) => (
                            <option key={index} value={region.region_name}>
                              {region.region_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {region === "Dakar" && (
                        <div className="animate__animated animate__fadeInDown">
                          <label
                            htmlFor="districts"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Choisissez votre district
                          </label>
                          <select
                            id="districts"
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            value={selectedDistrict}
                            onChange={handleDistrictChange}
                          >
                            <option value="" disabled>
                              Sélectionnez un district
                            </option>
                            {dakarDistrict.map((district, index) => (
                              <option key={index} value={district.district}>
                                {district.district}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor="fullName"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Nom complet
                      </label>
                      <input
                        type="text"
                        id="fullName"
                        className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                        placeholder="Votre nom complet"
                        required=""
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="phoneNumber"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Numéro de téléphone
                      </label>
                      <input
                        type="number"
                        id="phoneNumber"
                        className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                        placeholder="Votre numéro de téléphone"
                        required=""
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </div>
                </section>
              </div>

              <div className="grid lg:flex lg:justify-between lg:items-center grid-cols-1 space-y-5 lg:space-y-0 ">
                <div className="pl-3">
                  Total des articles :&nbsp;
                  {parseFloat(calculateTotal()).toFixed(
                    parseFloat(calculateTotal()) % 1 === 0 ? 0 : 2
                  )}{" "}
                  Fcfa
                </div>
                <div className="pl-3">
                  Livraison:&nbsp;
                  {districtPrice
                    ? `${parseFloat(districtPrice).toFixed(
                        calculateTotal() % 1 === 0 ? 0 : 2
                      )} Fcfa`
                    : "Prix non disponible"}
                </div>
                <div className="pl-3">
                  <span className="text-red-700 font-bold">
                    Total net à payer:
                  </span>{" "}
                  &nbsp;
                  <span className="font-bold text-red-600 bg-gray-200 px-1 py-1 rounded-sm">
                    {totalApayer} Fcfa
                  </span>
                </div>
                <div className="pl-3 flex pr-3">
                  <button
                    onClick={ConfirmerPaiement}
                    type="button"
                    className="bg-green-600 py-2 px-2 rounded-xl border-2 text-white font-semibold text-md"
                  >
                    Terminer
                  </button>
                  <button
                    data-modal-hide="paimentprocessing"
                    type="button"
                    className="bg-red-600 py-2 px-2 rounded-xl border-2 text-white font-semibold text-md"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentUtils;
